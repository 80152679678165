import React from 'react'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import styles from './index.module.scss'
import LocalizedLink from '../i18n/localizedLink'

const TopPage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const top = data.allContentfulTop.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={top.title}
        description={top.description}
        hrefLangs={hrefLangs}
      />
      <main role="main" className={styles.main}>
        <section className={styles.hero}>
          <h1 className={styles.hero_heading}>
            {top.information}
          </h1>
          <figure className={styles.hero_img}>
            <Img className={styles.imageWrapper} fluid={data.hero.childImageSharp.fluid} />
          </figure>
        </section>

        <section className={styles.products}>
          <h2><FormattedMessage id="products" /></h2>
          <div className={styles.productItems}>
            <div className={styles.item}>
              <a href="https://fulmo.loquat.md">
                <figure>
                  <Img fluid={top.productImage1.fluid} />
                </figure>
                <h3>{top.productName1}</h3>
                <p className={styles.overview}>{top.productOverview1}</p>
              </a>
            </div>
            <div className={styles.item}>
              <LocalizedLink to="/dermatology">
                <figure>
                  <Img fluid={top.productImage2.fluid} />
                </figure>
                <h3>{top.productName2}</h3>
                <p className={styles.overview}>{top.productOverview2}</p>
              </LocalizedLink>
            </div>
          </div>
        </section>

      </main>
    </Layout>
  )
}

export default TopPage

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulTop(filter: { node_locale: { eq: $localeLanguage } }) {
      edges {
        node {
          title
          description
          information
          productName1
          productName2
          productOverview1
          productOverview2
          productImage1 {
            fluid(cropFocus: CENTER, maxWidth: 1280, resizingBehavior: THUMB, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          productImage2 {
            fluid(cropFocus: CENTER, maxWidth: 1280, resizingBehavior: THUMB, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "top.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
